<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="QR Codes" subtitle="Create unique QR codes that participants can scan to become eligible." back="Convention.Schedule.Contests.Edit" :backParams="$route.meta.backParams" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Codes" icon="qrcode" :loading="is.generating" v-on:click="onSheetClick" />
		<app-content-head-actions-item :divide="true" text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no codes found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column />
				<app-table-column />

			</app-table-head>

			<app-table-body :sortable="sortedIndex" v-on:sorted="onSorted">

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-action icon="qrcode" v-tooltip="'View QR Code'" v-on:click="onQRClick(item.id)" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				generating: false
			},
			pagination: {
				perpage: 100000
			},
			layout: 'auto 24px 24px',
			gatekeep: 'convention/schedule/contest',
			endpoint: 'convention/schedule/contest/:contest/codes',
			live: 'convention/schedule/contest/:contest/codes'
		}

	},

	methods: {

		onSheetClick: function() {

			this.is.generating = true

			this.$api.post('qr/sheet', {
				index: this.selectedIndex
			}).then(function(json) {

				this.$present(json.pdf)

				this.is.generating = false

			}.bind(this), function() {

				this.is.generating = false

			}.bind(this)) 

		},

		onQRClick: function(id) {

			this.is.loading = true

			this.$api.get(['qr', id]).then(function(json) {

				this.$present(json.qr)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>